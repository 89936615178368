import {
  AnimatedNumber,
  Button,
  ButtonVariant,
  cn,
  CryptoCurrencyFormatter,
  ExplorerButton,
  ExplorerType,
  GraphTokenPrice,
  Icon,
  IconName,
  NumberFormatter,
  ProgressBar,
  ProgressBarColor,
  Tag,
  TagColor,
  TextS,
  TokenInfo,
  UnicodeChars,
} from '@tenset/components'
import { extractChain, formatUnits } from 'viem'
import { useAccount } from 'wagmi'

import { NftAirdropsTable } from './nft-table'

import type { NftAirdrop, PremiumAirdrop, SocialLinks } from '~/api/types'
import { AirdropStatus } from '~/api/types'
import { useWatchChainAsset } from '~/hooks'
import { daysPlural } from '~/utils'
import { getWagmiConfig, type ConfiguredChainId } from '~/wagmi-config'

export type AirdropItemTable = NftAirdrop | PremiumAirdrop

function isItemNft(item: AirdropItemTable): item is NftAirdrop {
  return 'nfts' in item
}

export function AirdropsTableItem(item: AirdropItemTable) {
  const {
    name,
    image,
    socials,
    currency,
    address,
    sponsorType,
    icon,
    network,
  } = item as NftAirdrop & PremiumAirdrop

  const socialIcons: Record<keyof SocialLinks, IconName> = {
    website: IconName.Website,
    twitter: IconName.TwitterMono,
    telegram: IconName.TelegramOutline,
    discord: IconName.Discord,
  }

  const isNft = isItemNft(item)

  const watchChainAsset = useWatchChainAsset({
    chainId: network,
    address,
    symbol: currency,
    decimals: 18,
    image,
  })

  const config = getWagmiConfig()

  const explorerUrl = extractChain({
    chains: config.chains,
    id: network as ConfiguredChainId,
  }).blockExplorers?.default?.url

  return (
    <div className="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-5 items-center py-8 md:py-6 gap-x-4 gap-y-8">
      <div className={cn('flex flex-col gap-8', isNft && 'sm:self-start')}>
        <TokenInfo name={name} currency={currency} image={image} icon={icon} />

        <Tag color={TagColor.NEUTRAL}>{sponsorType}</Tag>
      </div>

      <div
        className={cn(
          'flex flex-col gap-8 max-w-[80%]',
          isNft && 'sm:self-start'
        )}
      >
        <div className="flex justify-between gap-4">
          {Object.entries(socials).map(([key, value]) => {
            const IconName = socialIcons[key as keyof SocialLinks]

            return (
              <Button
                variant={ButtonVariant.SecondaryDarker}
                isIcon
                to={value}
                key={key}
              >
                <Icon name={IconName} size={22} color="white" />
              </Button>
            )
          })}
        </div>

        {address && network && (
          <ExplorerButton
            address={address}
            explorerUrl={explorerUrl}
            variant={ButtonVariant.SecondaryDarker}
            explorerType={ExplorerType.TOKEN}
            watchAsset={watchChainAsset}
            copyIconFilled
          />
        )}
      </div>

      {isNft ? (
        <AirdropsTableItemNft {...item} />
      ) : (
        <AirdropsTableItemPremium {...(item as PremiumAirdrop)} />
      )}
    </div>
  )
}

function AirdropsTableItemNft({ nfts }: NftAirdrop) {
  return (
    <div className="md:col-span-3 w-full">
      <NftAirdropsTable nfts={nfts} />
    </div>
  )
}

function AirdropsTableItemPremium({
  donatedTokens,
  myTokens,
  airdropStatus,
  price,
  airdropDuration: { numerator, denominator },
  graphData,
  decimals = 18,
}: PremiumAirdrop) {
  const { isConnected } = useAccount()

  const myTokensCurrentValue = price
    ? +formatUnits(myTokens, decimals) * price
    : 0

  const tokensCurrentValue = price
    ? +formatUnits(donatedTokens, decimals) * price
    : 0

  return (
    <>
      <div className="flex flex-col md:grid md:grid-cols-2 gap-6 md:col-span-2 text-center md:text-left items-center w-full">
        {isConnected && (
          <>
            <TextS tag="span" className="flex flex-col">
              <span className="font-semibold">My Tokens</span>

              <AnimatedNumber value={+formatUnits(myTokens, decimals)} />
            </TextS>

            <TextS tag="span" className="flex flex-col">
              <span className="font-semibold">My Tokens Current Value</span>

              {price ? (
                <AnimatedNumber
                  value={myTokensCurrentValue}
                  formatter={CryptoCurrencyFormatter}
                />
              ) : (
                <>{UnicodeChars.enDash}</>
              )}
            </TextS>
          </>
        )}

        <TextS tag="span" className="flex flex-col">
          <span className="font-semibold">Donated tokens</span>

          <NumberFormatter value={+formatUnits(donatedTokens, decimals)} />
        </TextS>

        <TextS tag="span" className="flex flex-col">
          <span className="font-semibold">Tokens Current Value</span>

          {tokensCurrentValue ? (
            <CryptoCurrencyFormatter
              value={tokensCurrentValue}
              precision={[2, 2]}
            />
          ) : (
            <>{UnicodeChars.enDash}</>
          )}
        </TextS>

        <div className="flex flex-col">
          <Tag
            color={
              airdropStatus === AirdropStatus.Completed
                ? TagColor.NEUTRAL
                : TagColor.GREEN
            }
          >
            Airdrop: {airdropStatus}
          </Tag>

          {airdropStatus === AirdropStatus.Active && (
            <ProgressBar
              className="w-full md:w-[200px]"
              numerator={numerator}
              denominator={denominator}
              color={ProgressBarColor.GREEN}
            />
          )}
        </div>

        <TextS tag="span" className="flex flex-col">
          <span className="font-semibold">Airdrop duration</span>
          {numerator} of {daysPlural(denominator)}
        </TextS>
      </div>

      <div className="h-4/5 w-full">
        <GraphTokenPrice data={graphData} />
      </div>
    </>
  )
}

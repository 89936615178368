import {
  Button,
  ButtonVariant,
  H3,
  Hero,
  HeroImage,
  VideoButton,
} from '@tenset/components'
import { useAccount } from 'wagmi'

import { InfinityLoop } from '~/assets/images'

export function HeroHomepageSection() {
  const { isConnected } = useAccount()

  const heroDescription = isConnected
    ? 'This platform brings a new dimension to the utility of the 10SET token by providing rewards to long-term holders, and done sustainably without inflation as all rewards come from transaction volume.'
    : 'By locking 10SET on Infinity, you will receive daily rewards from many different crypto projects, including the 10SET token itself. Some of these participating projects have not yet launched, so offer the chance to participate in early-stage projects with potential.'

  return (
    <div className="grid place-items-center">
      <Hero
        title="Tenset Infinity offers a totally unique airdrop system in the crypto space."
        description={heroDescription}
        rightContent={<HeroImage src={InfinityLoop} alt="Tenset Infinity" />}
        leftContent={
          <div className="flex flex-col gap-6">
            <H3 isBold>LET YOUR 10SET tokens work for you</H3>

            <div className="flex flex-col gap-4 md:flex-row">
              <Button to="/locks/create">See Staking Options</Button>

              <VideoButton
                title="Infinity explainer"
                src="https://infinity-static.b-cdn.net/io/infinity_2_1080p.mp4"
              />

              <Button
                variant={ButtonVariant.Ghost}
                to="https://docs.google.com/forms/d/12PxyUm5Vzl0Sj1RT13MZtJAlqkescvzjiTZSYLL3d4M/edit"
              >
                Get Listed
              </Button>
            </div>
          </div>
        }
      />
    </div>
  )
}

import {
  CryptoCurrencyFormatter,
  H3,
  NumberFormatter,
  Text,
} from '@tenset/components'

import { SummaryTable } from '~/components/common/tables'

export interface ArchivedAirdropsHomepageSectionProps {
  numberOfProjects: number
  redistributedTokens: number
  tokensAthValue: number
}

export function ArchivedAirdropsHomepageSection({
  numberOfProjects,
  redistributedTokens,
  tokensAthValue,
}: ArchivedAirdropsHomepageSectionProps) {
  const archivedArdropsSummary = {
    headers: {
      type: 'Type',
      value: 'Value',
    },
    items: [
      {
        type: 'Number of projects',
        value: <NumberFormatter value={numberOfProjects} />,
      },
      {
        type: 'Redistributed Tokens',
        value: (
          <NumberFormatter value={redistributedTokens} precision={[0, 0]} />
        ),
      },
      {
        type: 'ATH Value',
        value: (
          <CryptoCurrencyFormatter value={tokensAthValue} precision={[0, 0]} />
        ),
      },
    ],
  }

  return (
    <section className="flex flex-col gap-6">
      <header className="flex flex-col gap-4 md:mt-6">
        <H3 isBold>Archived airdrops</H3>

        <Text>Airdrops that ended more than 90 days ago.</Text>
      </header>

      <main>
        <SummaryTable table={archivedArdropsSummary} />
      </main>
    </section>
  )
}

import {
  cn,
  CryptoCurrencyFormatter,
  DataPoint,
  NumberFormatter,
} from '@tenset/components'

import { DataPointWithHeader } from '~/components/common/data-points'

export interface DataAboutHomepageSectionProps {
  airdropsValue: number
  lockedTensetTokens: number
  lockedTensetTokensValue: number
  userTotalLocked: number
  userAirdropsValue: number
  nftLocksAmount: number
}

export function DataAboutHomepageSection({
  airdropsValue,
  lockedTensetTokens,
  lockedTensetTokensValue,
  userTotalLocked,
  userAirdropsValue,
  nftLocksAmount,
}: DataAboutHomepageSectionProps) {
  return (
    <section
      className={cn(
        'grid gap-4 items-start',
        userAirdropsValue
          ? 'md:grid-cols-2'
          : 'md:grid-cols-2 xl:grid-cols-[1fr,3fr]'
      )}
    >
      <div
        className={cn(
          'grid gap-4 items-end',
          userAirdropsValue ? 'xl:grid-cols-2' : 'xl:grid-cols-1'
        )}
      >
        <DataPointWithHeader header="Airdrops" label="All Airdrops Value">
          <CryptoCurrencyFormatter value={airdropsValue} precision={[2, 2]} />
        </DataPointWithHeader>

        {!!userAirdropsValue && (
          <DataPointWithHeader label="My Airdrops Value">
            &asymp;&nbsp;
            <CryptoCurrencyFormatter
              value={userAirdropsValue}
              precision={[2, 2]}
            />
          </DataPointWithHeader>
        )}
      </div>

      <div
        className={cn(
          'grid gap-4 items-end',
          userAirdropsValue ? 'xl:grid-cols-2' : 'xl:grid-cols-3'
        )}
      >
        <DataPointWithHeader
          header="10set locked"
          label="Total Locked Tenset Tokens"
        >
          <NumberFormatter value={lockedTensetTokens} precision={[2, 2]} />
        </DataPointWithHeader>

        <DataPoint label="Total Tenset Token Value Locked">
          <CryptoCurrencyFormatter
            value={lockedTensetTokensValue}
            precision={[2, 2]}
          />
        </DataPoint>

        {!!userTotalLocked && (
          <DataPointWithHeader label="My Locked Tenset Tokens">
            <NumberFormatter value={userTotalLocked} precision={[2, 2]} />
          </DataPointWithHeader>
        )}

        {!!nftLocksAmount && (
          <DataPointWithHeader label="My Locked NFTs">
            <CryptoCurrencyFormatter
              value={nftLocksAmount}
              precision={[0, 0]}
              currency="NFTs"
            />
          </DataPointWithHeader>
        )}
      </div>
    </section>
  )
}

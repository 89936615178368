import type { LoaderFunctionArgs } from '@remix-run/node'
import { useEffect, useState } from 'react'
import { typedjson, useTypedLoaderData } from 'remix-typedjson'
import { formatEther } from 'viem'
import { useAccount } from 'wagmi'

import {
  adaptAboutData,
  adaptNftAirdrops,
  adaptPremiumAirdrops,
  adaptRegularAirdrops,
  adaptRewardsLastDayPerSecond,
} from '~/api/adapters'
import {
  fetchGraphs,
  fetchNftLocks,
  fetchPortfolioNft,
  fetchPrices,
  fetchRewards,
  fetchRewardsLastDay,
  fetchSponsorships,
  fetchStats,
  fetchStatsWallet,
  fetchTokens,
} from '~/api/fetchers/.server'
import type {
  PremiumAirdrop,
  RegularAirdrop,
  Reward,
  StatsWallet,
  TokenAmounts,
} from '~/api/types'
import { Airdrops } from '~/components/airdrops'
import {
  ArchivedAirdropsHomepageSection,
  HeroHomepageSection,
} from '~/sections/homepage'
import {
  DataAboutHomepageSection,
  TableAboutHomepageSection,
} from '~/sections/homepage/about'
import { getSession } from '~/sessions.server'
import { getSecondsSinceRewardsComputation, randomFloat } from '~/utils'

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request.headers.get('Cookie'))
  const sessionAddress = session.get('sessionAddress')

  const [sponsorships, portfolioNft, tokens, graphs, prices, stats] =
    await Promise.all([
      fetchSponsorships(),
      fetchPortfolioNft(),
      fetchTokens(),
      fetchGraphs(),
      fetchPrices(),
      fetchStats(),
    ])

  const tensetPrice = prices['10SET']

  let rewards: Reward[] = []
  let rewardsLastDayPerSecond: TokenAmounts = {}

  let statsWallet: StatsWallet = {} as StatsWallet

  let nftLocksAmount = 0

  if (sessionAddress) {
    const [_rewards, rewardsLastDay, _statsWallet, { lockedNftLocks }] =
      await Promise.all([
        fetchRewards(sessionAddress, sponsorships, tokens),
        fetchRewardsLastDay(sessionAddress),
        fetchStatsWallet(sessionAddress),
        fetchNftLocks(sessionAddress),
      ])

    rewards = _rewards
    rewardsLastDayPerSecond = adaptRewardsLastDayPerSecond(rewardsLastDay)
    statsWallet = _statsWallet
    nftLocksAmount = lockedNftLocks.length
  }

  return typedjson({
    ...adaptPremiumAirdrops(sponsorships, tokens, graphs, prices, rewards),
    ...adaptRegularAirdrops(stats, rewards, tensetPrice),
    ...adaptNftAirdrops(portfolioNft),
    aboutData: adaptAboutData(stats, tensetPrice),
    rewards,
    rewardsLastDayPerSecond,
    statsWallet,
    nftLocksAmount,
  })
}

export default function Index() {
  const {
    activeRegularAirdrops,
    activePremiumAirdrops,
    activeNftAirdrops,
    archivedPremiumAirdrops,
    recentlyCompletedPremiumAirdrops,
    recentlyCompletedNftAirdrops,
    aboutData: { airdropsValue, lockedTensetTokens, lockedTensetTokensValue },
    rewards,
    rewardsLastDayPerSecond,
    statsWallet: { userTotalLocked, userAirdropsValue },
    nftLocksAmount,
  } = useTypedLoaderData<typeof loader>()

  const { isConnected, address } = useAccount()

  const recentlyCompletedDescription = isConnected
    ? 'You must claim your tokens within 90 days of the airdrop ending.'
    : ''

  const [emulatedActiveAirdrops, setEmulatedActiveAirdrops] = useState({
    regular: activeRegularAirdrops,
    premium: activePremiumAirdrops,
  })

  useEffect(() => {
    setEmulatedActiveAirdrops({
      regular: activeRegularAirdrops,
      premium: activePremiumAirdrops,
    })

    if (!isConnected) return

    let emulateTimeout: NodeJS.Timeout

    function emulateAirdrop(airdrop: RegularAirdrop | PremiumAirdrop) {
      const airdropRewards = rewards.find(
        reward => reward.tokenData?.name === airdrop.name
      )

      if (!airdropRewards) return

      const { totalAmount: totalReward } = airdropRewards

      if (!totalReward) return

      const lastDayRewardPerSecond = BigInt(
        rewardsLastDayPerSecond[airdrop.currency] || 0
      )

      if (lastDayRewardPerSecond === 0n) return // Use 0n for bigint comparison

      const secondsSinceRecalculation = getSecondsSinceRewardsComputation()

      const todaysRewardUntilNow =
        lastDayRewardPerSecond * BigInt(secondsSinceRecalculation)

      const updatedMyTokens = totalReward + todaysRewardUntilNow

      function updateMyTokens(previousAirdrop: any) {
        return previousAirdrop.name === airdrop.name
          ? {
              ...previousAirdrop,
              myTokens: updatedMyTokens,
            }
          : previousAirdrop
      }

      setEmulatedActiveAirdrops(previousAirdrops => ({
        ...previousAirdrops,
        regular: previousAirdrops.regular.map(airdrop =>
          updateMyTokens(airdrop)
        ),
        premium: previousAirdrops.premium.map(airdrop =>
          updateMyTokens(airdrop)
        ),
      }))

      emulateTimeout = setTimeout(
        () => emulateAirdrop(airdrop),
        randomFloat(2000, 3000)
      )
    }

    const activeAirdrops = [...activeRegularAirdrops, ...activePremiumAirdrops]

    for (const airdrop of activeAirdrops) {
      emulateAirdrop(airdrop)
    }

    return () => clearTimeout(emulateTimeout)
  }, [
    address,
    rewards,
    rewardsLastDayPerSecond,
    activeRegularAirdrops,
    activePremiumAirdrops,
  ])

  const activeAirdrops = [
    ...emulatedActiveAirdrops.premium,
    ...activeNftAirdrops,
  ]
    .sort(
      ({ since: firstSince }, { since: secondSince }) =>
        secondSince.getTime() - firstSince.getTime()
    )
    .sort(
      ({ order: firstOrder }, { order: secondOrder }) =>
        firstOrder - secondOrder
    )

  const recentlyCompletedAirdrops = [
    ...recentlyCompletedPremiumAirdrops,
    ...recentlyCompletedNftAirdrops,
  ]
    .sort(
      ({ since: firstSince }, { since: secondSince }) =>
        secondSince.getTime() - firstSince.getTime()
    )
    .sort(
      ({ order: firstOrder }, { order: secondOrder }) =>
        firstOrder - secondOrder
    )

  return (
    <div className="container">
      <HeroHomepageSection />

      <section className="grid grid-rows-[1fr, 4fr] gap-32">
        <DataAboutHomepageSection
          airdropsValue={airdropsValue}
          lockedTensetTokens={lockedTensetTokens}
          lockedTensetTokensValue={lockedTensetTokensValue}
          userTotalLocked={+formatEther(userTotalLocked || 0n)}
          userAirdropsValue={userAirdropsValue}
          nftLocksAmount={nftLocksAmount}
        />

        <TableAboutHomepageSection
          tensetAirdrop={emulatedActiveAirdrops.regular[0]}
        />
      </section>

      <Airdrops
        title="Active airdrops"
        items={activeAirdrops}
        defaultTableSize={activeAirdrops.length}
      />

      <Airdrops
        title="Recently completed airdrops"
        description={recentlyCompletedDescription}
        items={recentlyCompletedAirdrops}
      />

      <ArchivedAirdropsHomepageSection {...archivedPremiumAirdrops} />
    </div>
  )
}

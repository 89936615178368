import type { DataPointProps } from '@tenset/components'
import { cn, DataPoint, H3 } from '@tenset/components'

export interface DataPointWithHeaderProps extends DataPointProps {
  header?: string
}

export function DataPointWithHeader({
  header,
  className,
  ...props
}: DataPointWithHeaderProps) {
  return (
    <div className={cn('flex flex-col gap-5', className)}>
      {header && (
        <header>
          <H3>{header}</H3>
        </header>
      )}

      <main>
        <DataPoint {...props} />
      </main>
    </div>
  )
}

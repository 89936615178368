import { Table, Tag, TagColor, UnicodeChars } from '@tenset/components'

import type { NftAirdropNft } from '~/api/types'
import { AirdropStatus } from '~/api/types'
import { daysPlural } from '~/utils'

interface NftAirdropsTableProps {
  nfts: NftAirdropNft[]
}

export function NftAirdropsTable({ nfts }: NftAirdropsTableProps) {
  const nftTableItemAdapter = ({
    name,
    airdropDuration,
    airdropStatus,
  }: NftAirdropNft) => {
    const regularPeopleAirdropStartDate = new Date('2024-02-09T00:00:00.000Z')
    const regularPeopleDaysLeft =
      Math.floor(
        (regularPeopleAirdropStartDate.getTime() - Date.now()) /
          (1000 * 60 * 60 * 24)
      ) + 1

    const daysLeft = airdropDuration.denominator - airdropDuration.numerator
    const daysLeftPlural =
      name === 'Regular People'
        ? `${regularPeopleDaysLeft} days to start the airdrop`
        : `${daysPlural(daysLeft)} left`

    const isAirdropActive = airdropStatus === AirdropStatus.Active

    return {
      name,
      daysLeft: isAirdropActive ? daysLeftPlural : <>{UnicodeChars.enDash}</>,
      status: (
        <Tag color={isAirdropActive ? TagColor.GREEN : TagColor.NEUTRAL}>
          Airdrop:&nbsp;
          {airdropStatus}
        </Tag>
      ),
    }
  }

  const nftsTableItemsAdapted = nfts.map(nft => nftTableItemAdapter(nft))

  return <Table items={nftsTableItemsAdapted} isRaw />
}

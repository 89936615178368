import { useState } from 'react'

import { AirdropsTable } from './table'
import type { AirdropItemTable } from './table-item'

import { SeeMoreButton } from '~/components/common/buttons'
import { TitleWithDescriptionContainer } from '~/components/common/containers'

export interface AirdropsProps {
  title: string
  description?: string
  items: AirdropItemTable[]
  defaultTableSize?: number
}

export function Airdrops({
  title,
  description,
  items,
  defaultTableSize = 3,
}: AirdropsProps) {
  const [isSeeMoreToggled, setIsSeeMoreToggled] = useState(false)

  const handleToggleChange = () => {
    setIsSeeMoreToggled(state => !state)
  }

  if (items.length === 0) return null

  return (
    <section className="flex flex-col gap-4">
      <header className="flex flex-col md:flex-row md:justify-between md:items-end gap-4">
        <TitleWithDescriptionContainer
          title={title}
          description={description}
          className="md:w-2/3 lg:w-2/5"
        />

        <div>
          <SeeMoreButton
            isToggled={isSeeMoreToggled}
            onClick={handleToggleChange}
            numberOfItemsToShow={items.length - defaultTableSize}
          />
        </div>
      </header>

      <main>
        <AirdropsTable
          items={isSeeMoreToggled ? items : items.slice(0, defaultTableSize)}
        />
      </main>
    </section>
  )
}

export * from './nft-table'
export * from './table'
export * from './table-item'

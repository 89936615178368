import type { GraphTokenPriceData, IconName } from '@tenset/components'

import type { SocialLinks, Token } from '~/api/types'

export enum AirdropStatus {
  Active = 'active',
  Completed = 'completed',
}

export enum SponsorType {
  Diamond = 'Diamond Sponsor',
  Gold = 'Gold Sponsor',
  Silver = 'Silver Sponsor',
  Bronze = 'Bronze Sponsor',
}

export interface AirdropDuration {
  numerator: number
  denominator: number
}

interface Airdrop {
  name: string
  image?: string
  icon?: IconName
}

interface TokenAirdrop extends Airdrop {
  address?: string
  network?: number
  currency: string
  decimals?: number
  donatedTokens: bigint
  myTokens: bigint
}

export interface RegularAirdrop extends TokenAirdrop {
  apy: [number, number]
  price: number
}

export interface PremiumAirdrop extends TokenAirdrop, Pick<Token, 'ath'> {
  socials: SocialLinks
  sponsorType: SponsorType
  airdropStatus: AirdropStatus
  airdropDuration: AirdropDuration
  graphData: GraphTokenPriceData[]
  until: Date
  since: Date
  order: number
  price?: number
}

export interface NftAirdrop extends Airdrop {
  socials: SocialLinks
  sponsorType: SponsorType
  order: number
  since: Date
  until: Date
  collection: string
  nfts: NftAirdropNft[]
}

export interface NftAirdropNft {
  name: string
  airdropDuration: AirdropDuration
  airdropStatus: AirdropStatus
}

import {
  AnimatedNumber,
  CryptoCurrencyFormatter,
  ExplorerButton,
  ExplorerType,
  IconName,
  NumberFormatter,
  TokenInfo,
  UnicodeChars,
} from '@tenset/components'
import { extractChain, formatEther } from 'viem'
import { useAccount } from 'wagmi'

import type { RegularAirdrop } from '~/api/types'
import { SummaryTable } from '~/components/common/tables'
import { useWatchChainAsset } from '~/hooks'
import { getWagmiConfig, tensetContract } from '~/wagmi-config'

export interface TableAboutHomepageSectionProps {
  tensetAirdrop: RegularAirdrop
}

export function TableAboutHomepageSection({
  tensetAirdrop,
}: TableAboutHomepageSectionProps) {
  const { isConnected } = useAccount()

  const config = getWagmiConfig()

  const explorerUrl = extractChain({
    chains: config.chains,
    id: tensetContract.chainId,
  }).blockExplorers?.default?.url

  const walletAirdropData = [
    {
      type: 'My Tokens',
      value: <AnimatedNumber value={+formatEther(tensetAirdrop.myTokens)} />,
    },
    {
      type: 'My Tokens Current Value',
      value: tensetAirdrop.price ? (
        <AnimatedNumber
          value={+formatEther(tensetAirdrop.myTokens) * tensetAirdrop.price}
          formatter={CryptoCurrencyFormatter}
        />
      ) : (
        <>{UnicodeChars.enDash}</>
      ),
    },
  ]

  const tensetSummary = {
    headers: {
      type: 'Type',
      value: 'Value',
    },
    items: [
      ...(isConnected ? walletAirdropData : []),
      {
        type: 'Redistributed Tokens',
        value: (
          <NumberFormatter
            value={+formatEther(tensetAirdrop.donatedTokens)}
            precision={[2, 2]}
          />
        ),
      },
      {
        type: 'Tokens Current Value',
        value: tensetAirdrop.price ? (
          <CryptoCurrencyFormatter
            value={
              +formatEther(tensetAirdrop.donatedTokens) * tensetAirdrop.price
            }
            precision={[2, 2]}
          />
        ) : (
          <>{UnicodeChars.enDash}</>
        ),
      },
      {
        type: 'APY',
        value: (
          <>
            <NumberFormatter value={tensetAirdrop.apy[0]} precision={[2, 2]} />
            <span>&nbsp;{UnicodeChars.enDash}&nbsp;</span>
            <NumberFormatter value={tensetAirdrop.apy[1]} precision={[2, 2]} />%
          </>
        ),
      },
    ],
  }

  const watchChainAsset = useWatchChainAsset({
    chainId: tensetContract.chainId,
    address: tensetContract.address,
    symbol: '10SET',
    decimals: 18,
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9089.png',
  })

  return (
    <section className="flex flex-col gap-6">
      <header className="flex justify-between md:w-1/2 md:pr-6">
        <TokenInfo name="Tenset" currency="10SET" icon={IconName.Tenset} />

        <ExplorerButton
          address={tensetContract.address}
          explorerUrl={explorerUrl}
          explorerType={ExplorerType.TOKEN}
          watchAsset={watchChainAsset}
          copyIconFilled
        />
      </header>

      <main>
        <SummaryTable
          table={tensetSummary}
          inscription={{
            title: 'Where do 10SET rewards come from?',
            description:
              'A 4% tax is placed on all 10SET token transactions. 2% goes to Infinity - half of this is allocated to 10SET token stakers, with the other half for TGLP NFT stakers.',
          }}
        />
      </main>
    </section>
  )
}

import { ListTable } from '@tenset/components'
import type { HTMLAttributes } from 'react'

import type { AirdropItemTable } from './table-item'
import { AirdropsTableItem } from './table-item'

export interface AirdropTableProps extends HTMLAttributes<HTMLDivElement> {
  items: AirdropItemTable[]
}

export function AirdropsTable({ items, className }: AirdropTableProps) {
  return (
    <ListTable
      elements={items.map((item, index) => (
        <AirdropsTableItem {...item} key={index} />
      ))}
      className={className}
    />
  )
}

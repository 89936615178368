import type { TableHeaders, TableItem } from '@tenset/components'
import { Table, Text, TextNumeric } from '@tenset/components'

import { TitleWithDescriptionContainer } from '../containers'

export interface SummaryTableProps {
  table: {
    headers: TableHeaders
    items: TableItem[]
  }
  inscription?: {
    title: string
    description: string
    action?: JSX.Element
  }
}

export function SummaryTable({ table, inscription }: SummaryTableProps) {
  return (
    <div className="grid gap-12 md:grid-cols-2">
      <Table
        headers={table.headers}
        items={table.items}
        isRaw
        typography={{
          header: Text,
          mainRow: Text,
          row: TextNumeric,
        }}
      />

      {inscription && <TitleWithDescriptionContainer {...inscription} />}
    </div>
  )
}

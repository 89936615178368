import { cn, H3, Text } from '@tenset/components'

export interface TitleWithDescriptionContainerProps {
  title: string
  description?: string
  action?: JSX.Element
  isTitleBold?: boolean
  className?: string
}

export function TitleWithDescriptionContainer({
  title,
  description,
  action,
  isTitleBold = false,
  className,
}: TitleWithDescriptionContainerProps) {
  return (
    <div className={cn('flex flex-col gap-4 md:mt-6', className)}>
      <H3 isBold={isTitleBold}>{title}</H3>

      {description && <Text>{description}</Text>}

      {action && <div>{action}</div>}
    </div>
  )
}
